// @flow strict
import React, { useRef, useState } from 'react';
import Contacts from '../Contacts';
import styles from './Landing.module.scss';
import { useSiteMetadata } from '../../hooks';
import { Link } from 'gatsby';
import CanvasDraw from "react-canvas-draw";

const Landing = () => {
  const { author } = useSiteMetadata();
	let saveableCanvas = useRef();
  const colors = ['#93989A'];
  const [state, setState] = useState({
    color: '#93989A'
  });
  setInterval(() => {
    if (saveableCanvas != null && typeof window !== `undefined`) {
      localStorage.setItem(
        "savedDrawing",
        saveableCanvas.getSaveData()
      );
    }
  }, 3000);
  return (
    <div className={styles['landing']}>
      <div className={styles['landing__canvas']}>
        <CanvasDraw className={styles['landing__canvas']}
          ref={(canvasDraw) => (saveableCanvas = canvasDraw)}
          onChange={() => {
            setState({
              color: colors[Math.floor(Math.random() * colors.length)]
            });
          }}
          immediateLoading={false}
          brushRadius={4}
          lazyRadius={0}
          catenaryColor={state.color}
          brushColor={state.color}
          hideGrid={true}
          imgSrc={'media/Hero.svg'}
          canvasWidth='100%'
          canvasHeight={'420px'}
          saveData={typeof window !== 'undefined' && localStorage.getItem("savedDrawing")}/>
      </div>
      <div className={styles['landing__bio']}>
        <p className={styles['landing__bio-header']}>
          {author.landing}
          <Link to='/about/' className={styles['landing__bio-about-link']}>
            {' Read more →'}
          </Link>
        </p>
        <figcaption className={styles['landing__bio-caption']}>
          {'Draw yourself on the whiteboard.'}
          <a className={styles['landing__bio-caption-clear']}
            onClick={() => {
              saveableCanvas.clear();
            }}>
            {' Clear ⌧'}
          </a>
        </figcaption>
        <div className={styles['landing__bio-contacts']}>
          <Contacts contacts={author.links} />
        </div>
      </div>
    </div>
  );
};

export default Landing;