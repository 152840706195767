// @flow strict
import React from 'react';
import moment from 'moment';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import type { Edges } from '../../types';
import styles from './LandingFeed.module.scss';

type Props = {
  edges: Edges
};

const LandingFeed = ({ edges }: Props) => (
  <div className={styles['landingfeed']}>
    {edges.map((edge) => (
      <div className={styles['landingfeed__item']} key={edge.node.fields.slug}>
        <Link className={styles['landingfeed__item-frame']} to={edge.node.fields.slug}>
          <Img className={styles['landingfeed__item-frame-thumbnail']} fluid={edge.node.frontmatter.img.childImageSharp.fluid} />
        </Link>
        <div className={'landingfeed__item-text'}>
          <Link to={edge.node.fields.categorySlug} className={styles['landingfeed__item-metadata-category']}>
            {edge.node.frontmatter.category}
          </Link>
          <h2 className={styles['landingfeed__item-text-title']}>
            <Link className={styles['landingfeed__item-text-title-link']} to={edge.node.fields.slug}>{edge.node.frontmatter.title}</Link>
          </h2>
          <Link className={styles['landingfeed__item-text-description']} to={edge.node.fields.slug}>{edge.node.frontmatter.description}</Link>
          <br/>
        </div>
        <div className={'landingfeed__item-metadata'}>
          <time className={styles['landingfeed__item-metadata-time']} dateTime={moment(edge.node.frontmatter.date)}>
            {moment(edge.node.frontmatter.date).format('YYYY MM DD')}
          </time>
          <br/>
          <span className={styles['landingfeed__item-metadata-readtime']}>
            {edge.node.fields.readingTime.text}
          </span>
          <br/>
        </div>
      </div>
    ))}
  </div>
);

export default LandingFeed;
